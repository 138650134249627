<template>
  <div class="content">
    <div class="row m-4">
      <div class="col-12" ref="contentCard">
        <card class="d-flex flex-column" >
          <CustomerAppointments
            v-if="!isLoading"
            :customerBookings="bookings"
            @openBooking="openBooking"
            @reloadBookings="reloadBookings"
          />
          <Spinner
            v-else
            parentClasses="justify-content-center"
          ></Spinner>
          <template slot="footer">
            <pagination
              v-if="bookings.length > 0"
              @turnPage="getBookings"
              element="booking"
              :perPage="pagination.perPage"
              :lastPage="pagination.lastPage"
              :totalItems="pagination.totalItems"
              :isChanging="pagination.isChanging"
              :currentPage="currentPage"
            ></pagination>
          </template>
        </card>
      </div>
      <modal
        v-if="isShowBookingInfo"
        :show="isShowBookingInfo"
        :showClose="false"
        :centered="false"
        class="ContainerModal"
      >
        <div class="modal-info">
          <div class="text-right">
            <base-button type="simple" class="p-2 col-2 closeButton" @click="isShowBookingInfo = false">
              <em class="tim-icons icon-simple-remove"></em>
            </base-button>
          </div>
          <div class="row py-3 mx-1 mb-1">
            <div class="order-0 d-flex flex-wrap align-items-center justify-content-sm-center col-12 col-sm-3 containerDate">
              <p class="order-1 order-sm-0 text-center">
                {{ $t( `bookings.months.${ new Date(bookingOpen.datetime).getMonth() + 1 }` ) }}
              </p>
              <h3 class="mb-1 pr-2 px-sm-0 order-0 order-sm-1 text-dark text-center">
                {{ new Date(bookingOpen.datetime).getDate() }}
              </h3>
              <p class="order-2 pl-2 text-center">
                {{
                  !(new Date(bookingOpen.datetime).getHours() >= 10)
                    ? "0" + new Date(bookingOpen.datetime).getHours()
                    : new Date(bookingOpen.datetime).getHours()
                }}
                :
                {{
                  !(new Date(bookingOpen.datetime).getMinutes() >= 10)
                    ? "0" + new Date(bookingOpen.datetime).getMinutes()
                    : new Date(bookingOpen.datetime).getMinutes()
                }}h.
              </p>
              <div class="d-sm-none order-3 pill">
                <tabs-pills
                  :type="bookingOpen.color"
                  rowClass="pillContainer"
                  tabNavWrapperClasses="mb-2"
                  tabContentClasses="d-none"
                  tabListClasses="py-0 cursor-default col-12"
                >
                  <tab-pane
                    :label="$t(`bookings.status.${bookingOpen.status}`)"
                  ></tab-pane>
                </tabs-pills>
              </div>
            </div>
            <div class="order-2 order-sm-1 separator my-1" />
            <div class="order-1 order-sm-2 col-6 align-self-center justify-content-between pill">
              <tabs-pills
                  :type="bookingOpen.color"
                  rowClass="pillContainer"
                  tabNavWrapperClasses="mb-2"
                  tabContentClasses="d-none"
                  tabListClasses="d-none d-sm-block py-0 px-5 cursor-default col-12 order-2"
                >
                  <tab-pane
                    :label="$t(`bookings.status.${bookingOpen.status}`)"
                  ></tab-pane>
                </tabs-pills>
              
                <p class="order-0">{{ bookingOpen.service.name }}</p>
                <p class="font-size text-muted order-1">
                  {{ bookingOpen.service.time }}min <strong class="px-1">·</strong>
                  {{ bookingOpen.employee.name }}
                </p>
            </div>
            <div class="order-3 order-sm-3 col-3 col-sm-2 d-flex align-items-center p-0 text-center m-auto containerPrice">
              <h3 class="col-12 font-weight-bold p-0 m-0 text-dark">{{bookingOpen.service.price}} €</h3>
            </div>
           
          </div>

          <Spinner
            v-if="loadingStatus"
            class="justify-content-center"
          ></Spinner>
          <div
            v-if="!loadingStatus && !['rejected', 'canceled'].includes(bookingOpen.status)"
            class="justify-content-between gap-3 managmentAppointment flex-wrap"
          >
            <base-button class="px-0 px-sm-4 w-100" type="info" :disabled=" bookingOpen.status === 'confirmed' || bookingOpen.status === 'new'" @click="changeStatus('confirmed')">
              <span class="text-white p-0 m-0">{{ $t("confirmAppointment") }}</span>
            </base-button>

            <base-button class="px-0 px-sm-4 w-100" type="danger" @click="showConfirmCancel = true">
              <span class="text-white p-0 m-0">{{ $t("cancelAppointment") }}</span>
            </base-button>
          </div>
          <hr />
          <div>
            <p>
              <span class="font-weight-bold">
                {{ $t("bookingInfo.commentFromStore") }}:</span
              >
              <span class="d-block">
                {{
                  bookingOpen.store_comment
                    ? bookingOpen.store_comment
                    : $t("noComment")
                }}
              </span>
            </p>
            <base-textarea
              class="bookingOpen-textarea"
              :label="`${$t('bookingInfo.commentToStore')}:`"
              v-model="bookingOpen.customer_comment"
            >
            </base-textarea>
          </div>
          <div class="d-block d-sm-flex justify-content-between">
            <base-button
              class="sendCommentBtn"
              type="success"
              @click="sendComment()"
              :disabled="!hasNewComment"
            >
              {{ $t("sendComment") }}
            </base-button>
          </div>
        </div>
      </modal>
      <modal :show="showConfirmLeave" :showClose="false" :centered="false">
        <template slot="header">
          <h5 class="modal-title">{{ $t("confirm.hasUnsavedChanges") }}</h5>
        </template>
        <template slot="footer">
          <base-button type="secondary" @click="showConfirmLeave = false">
            {{$t("cancel")}}
          </base-button>
          <base-button type="danger" @click="closeBooking(true)">
            {{$t("confirm.continue")}}
          </base-button>
        </template>
      </modal>
      <modal :show="showConfirmCancel" :showClose="false" :centered="false">
        <template slot="header">
          <h5 class="modal-title">{{ $t("confirm.cancelAppointment") }}</h5>
        </template>
        <template slot="footer">
          <base-button type="secondary" :disabled="loadingStatus" @click="showConfirmCancel = false">{{
            $t("goBack")
          }}</base-button>
          <base-button type="danger" :disabled="loadingStatus" @click="changeStatus('canceled')">{{
            $t("cancelAppointment")
          }}</base-button>
        </template>
      </modal>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { DateTime } from "luxon";
import {
  Spinner,
  Pagination,
  Card,
  Modal,
  TabsPills,
  TabPane,
  BaseTextarea,
} from "@/components/index";
import CustomerAppointments from "@/views/Customers/CustomerAppointments.vue";

export default {
  name: "MyAppointments",
  data() {
    return {
      routeBookings: "/bookings",
      routeEmployees: "/employees",
      routeStores: "/stores",
      isLoading: true,
      bookings: [],
      pagination: {
        perPage: 10,
        lastPage: 1,
        totalItems: null,
        isChanging: false
      },
      isShowBookingInfo: false,
      bookingOpen: {},
      showConfirmLeave: false,
      showConfirmCancel: false,
      loadingStatus: false,
      currentPage: 1
    };
  },
  components: {
    Spinner,
    CustomerAppointments,
    Pagination,
    Card,
    Modal,
    TabsPills,
    TabPane,
    BaseTextarea,
  },
  computed: {
    ...mapState(["isManager", "isEmployee", "locale", "defaultTitle"]),
    hasNewComment() {
      let booking = this.bookings.find(
        (event) => event.uuid === this.bookingOpen.uuid
      );
      return booking
        ? booking.customer_comment !== this.bookingOpen.customer_comment
        : false;
    },
  },
  methods: {
    async getBookings(page = 1, isGoToUp = true) {
      this.currentPage = page;
      this.pagination.isChanging = true;
      const route = `${this.routeBookings}?page=${this.currentPage}`;
      let response = await this.axios.get(route);
      if (response && response.data.status === "success") {
        const data = response.data.data.bookings;
        this.currentPage = data.current_page;
        this.bookings = data.data;
        this.pagination.perPage = data.per_page;
        this.pagination.lastPage = data.last_page;
        this.pagination.totalItems = data.total;
        if( isGoToUp ) this.goToUp();
      }
      this.pagination.isChanging = false;
      this.isLoading = false;
    },
    openBooking(booking) {
      this.bookingOpen = { ...booking };
      this.isShowBookingInfo = true;
    },
    async sendComment() {
      const route = `${this.routeBookings}/${this.bookingOpen.uuid}`;
      let data = {
        employee_uuid: this.bookingOpen.employee.uuid,
        datetime: DateTime.fromJSDate(new Date(this.bookingOpen.datetime)).toFormat('yyyy-MM-dd HH:mm:ss'),
        customer_comment: this.bookingOpen.customer_comment,
      };
      let response = await this.axios.put(route, data);
      if (response && response.data.status === "success") {
        this.bookings = this.bookings.map((event) => {
          if (event.uuid === this.bookingOpen.uuid) {
            event = this.bookingOpen;
          }
          return event;
        });
        this.closeBooking(true);

        this.$toast.success(this.$t("bookingMessage.sucessfulComment"));
      }
    },
    closeBooking(close) {
      if (close) {
        this.showConfirmLeave = false;
        this.bookingOpen = {};
        this.isShowBookingInfo = false;
        return;
      }
      if (this.hasNewComment) {
        this.showConfirmLeave = true;
      } else {
        this.closeBooking(true);
      }
    },
    async changeStatus(status) {
      this.loadingStatus = true;
      const route = `${this.routeBookings}/${this.bookingOpen.uuid}/status`;
      let data = {
        status,
      };
      let response = await this.axios.put(route, data);

      if (response && response.data.status === "success") {
        this.bookingOpen.status = status;
        this.$toast.success(this.$t(`bookingMessage.successful-${status}`));
        let bookingIndex;
        bookingIndex = this.bookings.findIndex(
          (booking) => booking.uuid === this.bookingOpen.uuid
        );
        this.bookings.splice(bookingIndex, 1, this.bookingOpen);
        if (status === "canceled") {
          this.showConfirmCancel = false;
          this.closeBooking(true);
        }
      }
      this.loadingStatus = false;
    },
    reloadBookings() {
      this.getBookings(this.currentPage, false);
    },
    goToUp() {
      if( this.$refs && this.$refs.contentCard ) {
        this.$refs.contentCard.scrollIntoView({block: "start", behavior: "smooth"})
      } 
    }
  },
  metaInfo() {
    return { title: `${this.$t('myAppointments')} - ${this.defaultTitle}` }
  },
  mounted() {
    this.getBookings();
  },
};
</script>

<style lang="scss">
@import '../../assets/scss/white-dashboard.scss';

.event-font {
  font-size: 10px;
  font-weight: 400;
}
.control-label {
  font-weight: 600;
  color: #222a42;
}
.font-size {
  font-size: 12px;
}
.separator {
  border-right: 1px solid #e9ecef;
}
.containerDate {
  height: auto;
}
.containerDate > h3 {
  min-width: 35px;
}
.containerPrice > h3 {
  min-width: 75px;
}
.closeButton {
  border: none;
  max-width: 2.5rem;
}
.closeButton:hover {
  border: none;
}
.ContainerModal > .modal-dialog > .modal-content {
  background: transparent;
  box-shadow: none;
}
.modal-info {
  box-shadow: 0 15px 35px #32325d33, 0 5px 15px #0000002b;
  background: #fff;
  border-radius: 0.2857rem;
  padding: 2em;
  margin: 10px 0;
}

@media (min-width: 330px) {
  .pill {
    margin-right: auto;
    width: 100%;
  }
}
@media (min-width: 415px) {
  .pill {
    margin-right: 0;
  }
  .managmentAppointment {
    display: flex;
    gap: 10px;
  }
}
@media (min-width: 575px) {
  .containerDate > h3 {
    min-width: 50px;
  }
  .managmentAppointment > button {
    max-width: 190px;
  }
  .backBtn {
    flex: 0 0 45%;
    max-width: 45%;
  }
}
</style>
